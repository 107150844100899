<template>
  <Dialog v-model:visible="display" header="添加分配菜单" @hide="$emit('close')" :modal="true" style="width: 30rem;">
    <Tree style="border:none;padding:0" :value="allList" selectionMode="checkbox" v-model:selectionKeys="checkeds" display="chip">
      <template #default="slotProps">
        <b>{{slotProps.node.name}}</b>
      </template>
    </Tree>
    <template #footer>
      <Button @click="display=false" class="p-button-text">取消</Button>
      <Button @click="submit">保存</Button>

    </template>
  </Dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Tree from "primevue/tree";
export default {
  name: "addOrUpdateRole",
  components: { Tree },
  setup: () => ({
    v$: useVuelidate(),
  }),
  data() {
    return {
      checkeds: {},
      display: false,
      submitted: false,
      checked: false,
      form: {
        name: null,
        code: null,
        enable: null,
      },
      isAdd: true,
      allList: null,
      roleId: null,
      nodes: [],
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        code: { required },
        enable: {},
      },
    };
  },
  methods: {
    init(data) {
      this.display = true;
      this.roleId = data.id;
      let m = this;
      this.$http(
        "/role/getMenuListByRoleId/" + data.id,
        "get",
        null,
        function (res) {
          let l = [];
          console.log(res.data);
          res.data.allList.forEach((item) => {
            item.key = item.id;
            if (item.children && item.children.length > 0) {
              item.children.forEach((c) => {
                c.key = c.id;
                if (c.children && c.children.length > 0) {
                  c.children.forEach((d) => {
                    d.key = d.id;
                    if (d.children && d.children.length > 0) {
                      d.children.forEach((e) => {
                        e.key = e.id;
                      });
                    }
                  });
                }
              });
            }
            l.push(item);
          });
          m.allList = l;

          res.data.selectedList.forEach((item) => {
            m.checkeds[item.resId] = { checked: true };
          });
        }
      );
    },
    submit() {
      for (let i of Object.keys(this.checkeds)) {
        if ((i == 'undefined')) {
          delete this.checkeds[i]
        }
      }
      this.submitted = true;
      let m = this;
      this.$http(
        "/role/addOrUpdateMenuToRole",
        "post",
        {
          roleId: m.roleId,
          menuIds: Object.keys(m.checkeds),
        },
        (res) => {
          m.display = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.p-fluid {
  width: 450px;
}

/*.p-fluid {*/
/*  margin: 0 auto 2rem;*/
/*}*/

label {
  width: 200px;
  text-align: right;
  justify-content: flex-end;
}

.p-field > span {
  width: 100%;
  padding-left: 200px;
  line-height: 34px;
  color: red;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
